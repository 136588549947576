import React, { useEffect, useRef } from "react";
import { handleMessages, initWebChat } from "./Chatbot.utils";
import {  SERVER_URL } from "./Chatbot.constants";
import { ChatbotProps } from "./Chatbot.types";

export const Chatbot: React.FC<ChatbotProps> = (props: ChatbotProps) => {
  const { botId } = props;
  const scriptRef = useRef<HTMLScriptElement | null>(null);

  useEffect(() => {
    if (!botId) return;
    const scriptUrl = `${SERVER_URL}/assets/modules/channel-web/inject.js`
    const script = document.createElement("script");
    scriptRef.current = script;
    script.src = scriptUrl;
    script.async = true;
    document.body.appendChild(script);

    script.onload = () => {
      initWebChat(botId); // pass the locale to initWebChat
    };

    window.addEventListener("message", handleMessages);

    return () => {
      document.body.removeChild(script);
      window.removeEventListener("message", handleMessages);
    };
  }, [botId]);

  return <></>;
};