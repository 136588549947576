import { useEffect, useState } from "react";
import "./App.css";
import { Chatbot } from "./components/Chatbot/Chatbot";
import { showChat } from "./components/Chatbot/Chatbot.utils";

import { REACT_HOST_URL } from "./components/Chatbot/Chatbot.constants";


const isDevelopment = process.env.NODE_ENV === "development";

function App() {
  const [botId, setBotId] = useState<string | null>(null);

  const onResetConversation = () => {
    window.botpressWebChat.sendEvent({ type: "createConversation" });
  };

  const onShowChat = () => {
    showChat();
  };

  const stripLastCharacterIfSlash = (str: string) => {
    if (str.endsWith('/')) {
      return str.slice(0, -1);
    }
    return str;
  }


  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const botId = urlParams.get("botId");
      botId && setBotId(stripLastCharacterIfSlash(botId));
    }
    }, []);


  return (
    <div className="App">
      <Chatbot botId={botId} />
      {isDevelopment && (
        <div className="developer-tools">
          <h3>Developer tools:</h3>
          <button onClick={onResetConversation}>Reset conversation</button>
          <button onClick={onShowChat}>Open chat</button>
        </div>
      )}
    </div>
  );
}

export default App;
