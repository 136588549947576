import {  SERVER_URL, CHATBOT_URL, REACT_HOST_URL } from "./Chatbot.constants"


const reservedUserEvents = ["User visit", "undefined"]

export const setWelcomeMessageWasSent = () => {
    sessionStorage.setItem("welcomeMessageWasSent", "true")
}

export const initWebChat = (botId: string) => {
    console.log("initWebChat", botId)
    window.botpressWebChat.init({
        chatId: "bp-web-widget",
        botId: botId,
        host: SERVER_URL,
        extraStylesheet: CHATBOT_URL + "/styles/chatbot.css",
        containerWidth: "100%",
        layoutWidth: "100%",
        hideWidget: true,
        enableConversationDeletion: true,
        useSessionStorage: true,
    })
}

export const showChat = () => {
    const welcomeMessageWasSent = sessionStorage.getItem("welcomeMessageWasSent") === "true"

    window.botpressWebChat.sendEvent({ type: "show" });
    if (!welcomeMessageWasSent) setTimeout(() => {
        window.botpressWebChat.sendPayload({ type: "trigger" });
        setWelcomeMessageWasSent()
    }, 3000)
}

export const handleMessages = (event: MessageEvent) => {
    if (event.origin === REACT_HOST_URL) handleHostMessages(event);
    if (event.origin === SERVER_URL) handleServerMessages(event);
};

const handleHostMessages = async (event: MessageEvent) => {
    if (event.data.type === "show") {
        showChat();
    }
};

const handleServerMessages = (event: MessageEvent) => {
    if (event.data.payload?.data?.node === 'user-search' ) {
        const userSearch = event.data.payload.data.text;
        if (reservedUserEvents.includes(userSearch)) return;
        handleUserSearch(userSearch);
        return;
    }
    handlePostback(event);
    if (event.data.name === "webchatClosed") {
        window.parent.postMessage("webchatClosed", REACT_HOST_URL);
    }
};

const handleUserSearch = (userSearch: any) => {
    const url = '/search' + '?query=' + userSearch
    handleUrlChange(url);
};

const handlePostback = (event: MessageEvent) => {
    if (event.data.payload?.type === "postback") {
        const payload = JSON.parse(event.data.payload.payload);
        if ("redirectUrl" in payload) {
            handleUrlChange(payload.redirectUrl);
        }
    }
};

const handleUrlChange = (redirectUrl: string) => {
    window.parent.postMessage({ redirectUrl }, REACT_HOST_URL);
};